body {
    margin: 0;
    font-family: 'Poppins', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

html, body, #root {
    height: 100%;
    width: 100%;
}

a {
    text-decoration: none;
}

#root[aria-hidden] {
    overflow: hidden;
}

/* width */
::-webkit-scrollbar {
    width: 8px;
    height: 8px;
}

.react-tabs__tab-panel--selected {
    height: 100%;
}

.react-pdf__Page__annotations.annotationLayer {
    padding: 20px;
}

.react-pdf__Page {
    /*border: 1px solid darkgrey;*/
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    border-radius: 5px;
    margin-bottom: 15px;
}

.rpv-core__inner-page {
    margin: 3rem;
}

insertion {

    color: #0099ff;
    text-decoration: underline;

}

delation {

    color: red;
    text-decoration-line: line-through;

}